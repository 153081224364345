import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Typography,
  Space,
  Statistic,
  Tag,
  Tooltip,
  Button,
  Modal,
} from "antd";
import {
  ShoppingOutlined,
  UsergroupAddOutlined,
  ShoppingCartOutlined,
  BellOutlined,
  DotChartOutlined,
  UserOutlined,
  CheckCircleOutlined,
  TruckOutlined,
  SendOutlined,
  BoxPlotOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import OrderForm from "../Order/OrderForm";
import formatDate from "../../Components/formatDate";
import { ReadRecord, UpdateRecord } from "../../Service/API/ApiService";

const Dashboard = () => {
  const currentUrl = window.location.href;
  const [form] = Form.useForm();
  const [orders, setOrders] = useState(0);
  const [inventory, setInventory] = useState(0);
  const [dispatch, setDispatched] = useState(0);
  const [alert, setAlert] = useState(0);
  const [ribbonOptions, setRibbonOptions] = useState([]);
  const [orderDetails, setOrderDatils] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewOrders, setViewOrders] = useState(null);
  const [JumboOptions, setJumbodetails] = useState(null);
  const [LabelOptions, setLabeldetails] = useState(null);
  const [customerOptions, setCustomerDetails] = useState(null);
  const [LocationOptions, setLocationDetails] = useState(null);
  const [MaterialOptions, setMaterialDetails] = useState(null);
  const [selectedCoreId, setSelectedCoreId] = useState(null);
  const [typeFilter, settypeFilter] = useState("");
  const loggedinUserId = localStorage.getItem("userID");
  const loggedinuser = localStorage.getItem("usernameHarnet");
  console.log("loginusername", loggedinUserId);

  const [customers, setCustomers] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getTotalOrder();
    getCustomerTotal();
    getInventoryTotal();
    getDispatchTotal();
    getAlertCount();
    // Replace with actual fetch logic to get notifications from backend
    // For demo, using static data

    // Static notifications for demonstration
    // setNotifications([
    //   { message: "Order 001 has dispatched with serial number 12345", status: "Dispatched" },
    //   { message: "Order 002 has been delivered with serial number 12345", status: "Delivered" },
    //   { message: "Order 002 has been delivered with serial number 12345", status: "Delivered" },
    //   { message: "Order 002 has been delivered with serial number 12345", status: "Delivered" },
    //   { message: "Order 002 has been delivered with serial number 12345", status: "Delivered" },
    //   // Add more notifications as needed
    //   { message: "Order 001 has dispatched with serial number 12345", status: "Dispatched" },
    //   { message: "Order 001 has dispatched with serial number 12345", status: "Dispatched" },
    //   { message: "Order 001 has been moved to Production", status: "In Progress" },
    //   { message: "Order 001 has dispatched with serial number 12345", status: "Dispatched" },
    //   { message: "Order 001 has been moved to Production", status: "Dispatched" },
    //   { message: "Order 001 has dispatched with serial number 12345", status: "Dispatched" },
    //   { message: "Order 001 has been moved to Production", status: "In Progress" },
    //   { message: "Order 001 has been moved to Production", status: "In Progress" },
    //   { message: "Order 001 has dispatched with serial number 12345", status: "Dispatched" },
    //   { message: "Order 001 has been moved to Production", status: "In Progress" },
    //   { message: "Order 001 has dispatched with serial number 12345", status: "Dispatched" },
    // ]);

    fetchNotifications();
  }, []);

  function getTotalOrder() {
    ReadRecord(`SELECT COUNT(*) as TotalCount
        FROM  orders AS t1 
         JOIN 
        customer_master AS t2 ON t1.customer_id = t2.id 
    JOIN 
        location_master AS t3 ON t1.location_id = t3.id 
    JOIN 
        jumbo_master AS t5 ON t1.jumbo_id = t5.id 
    JOIN 
        user_master AS t6 ON t1.user_id = t6.id        
     JOIN 
         material_master AS t7 ON t5.material_id = t7.id

        WHERE t1.status = 1 and t1.ActiveStatus = 1 and order_type like '%%';
`).then((res) => {
      setOrders(res.data[0]?.rows[0].TotalCount || 0);
      console.log("order data", res.data[0]?.rows[0].TotalCount);
    });
  }
  function getAlertCount() {
    ReadRecord(
      `select Count(*) as TotalCount from notifications where user_id='${loggedinUserId}' and ActiveStatus=1`
    ).then((res) => {
      setAlert(res.data[0]?.rows[0].TotalCount || 0);
    });
  }

  function getCustomerTotal() {
    ReadRecord(
      `select Count(*) as TotalCount from customer_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setCustomers(res.data[0]?.rows[0].TotalCount || 0);
    });
  }
  function getInventoryTotal() {
    ReadRecord(
      `SELECT FORMAT(SUM(quantity), 2) AS TotalQuantity 
FROM store 
WHERE status = 1 
  AND ActiveStatus = 1;
;
`
    ).then((res) => {
      setInventory(res.data[0]?.rows[0].TotalQuantity || 0);
    });
  }
  function getDispatchTotal() {
    ReadRecord(`select COUNT(*) AS TotalQuantity from dispatch 
      where status=1 and ActiveStatus=1`).then((res) => {
      setDispatched(res.data[0]?.rows[0].TotalQuantity || 0);
    });
  }

  useEffect(() => {
    setLoading(true);
    // Fetch recent orders data
    ReadRecord(`SELECT t1.*,t2.customer_name FROM orders t1 join customer_master t2 on t1.customer_id=t2.id 
    WHERE t1.status = 1 AND t1.ActiveStatus = 1 and order_status='Awaiting Approval'
     ORDER BY t1.order_number DESC LIMIT 10`)
      .then((res) => {
        if (res.data[0]?.rows) {
          setDataSource(res.data[0].rows);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching recent orders:", error);
        setLoading(false);
      });
  }, []);

  function getTagColor(status) {
    switch (status) {
      case "Dispatched":
        return "green";
      case "Awaiting Approval":
        return "gold";
      case "In Progress":
        return "orange";
      case "Delivered":
        return "blue";
      default:
        return "default";
    }
  }

  function getTagListColor(status) {
    // console.log("show all status", status);
    // if(status=='Approved'){
    //   return 'red';
    // }
    switch (status) {
      case "Awaiting Approval":
        return "gold";
      case "Dispatch":
        return "green";
      case "Approved":
        return "purple";
      case "Rejected":
        return "Brown";
      case "Cancelled":
        return "orange";
      default:
        return "default";
    }
  }

  const fetchNotifications = () => {
    ReadRecord(
      `select id,notification,order_status from notifications where ActiveStatus=1 order by id desc `
    ).then((res) => {
      setNotifications(res.data[0]?.rows);
    });
  };

  const handleClearNotification = (notificationId) => {
    console.log("notificationId", notificationId);
    let data = {
      id: notificationId,
      status: false,
      ActiveStatus: false,
    };
    UpdateRecord("notifications", [data]).then((res) => {
      console.log("notification cleared", res);
      fetchNotifications();
    });
  };
  function getCustomerDetails() {
    ReadRecord(
      `select * from customer_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setCustomerDetails(res.data[0]?.rows);
    });
  }
  function getJumboDetails() {
    ReadRecord(
      `select id,jumbo_type from jumbo_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setJumbodetails(res.data[0]?.rows);
    });
  }
  function getLocationDetails() {
    ReadRecord(
      `select * from location_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setLocationDetails(res.data[0]?.rows);
    });
  }
  const fetchRibbonOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,ribbon_length,ribbon_width,ribbon_measure FROM ribbon_master WHERE status = 1 and ActiveStatus = 1`
      );
      setRibbonOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Label options:", error);
    }
  };
  function getMaterialDetail() {
    ReadRecord(
      `select id,material_name from material_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setMaterialDetails(res.data[0]?.rows);
    });
  }
  function getLabelDetails() {
    ReadRecord(
      `select id,label_length,label_width,label_measure from label_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setLabeldetails(res.data[0]?.rows);
    });
  }
  function getCoreDetails() {
    ReadRecord(
      `select id,core_type,core_size,core_dia,core_dia_measure,core_length,core_length_measure from core_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setSelectedCoreId(res.data[0]?.rows);
    });
  }
  const fetchOrderData = async (data) => {
    const response = await ReadRecord(`SELECT 
        t1.*, 
        t2.customer_name, 
        t3.location_name,
        t7.material_name,
        t7.material_name AS material_id,
        t5.jumbo_type,
        t5.type,
        t6.username 
    FROM 
        orders AS t1 
    JOIN 
        customer_master AS t2 ON t1.customer_id = t2.id 
    JOIN 
        location_master AS t3 ON t1.location_id = t3.id 
    JOIN 
        jumbo_master AS t5 ON t1.jumbo_id = t5.id 
    JOIN 
        user_master AS t6 ON t1.user_id = t6.id        
    JOIN 
        material_master AS t7 ON t5.material_id = t7.id
    WHERE 
        t1.status = 1 
        AND t1.ActiveStatus = 1 AND  t1.order_number='${data}' limit 1`);
    // console.log("response", response.data[0].rows[0]);

    return response.data[0].rows[0];
  };
  const handleOrderModalPopup = async (value) => {
    setLoading(true);
    setIsModalVisible(true);
    getJumboDetails();
    getCustomerDetails();
    getLocationDetails();
    getMaterialDetail();
    getLabelDetails();
    fetchRibbonOptions();
    getCoreDetails();
    setOrderDatils(value);
    const values = await fetchOrderData(value);
    if (values) {
      const newRecords = { ...values };
      console.log("handleOrderModalPopup", values);
      setViewOrders(newRecords);
      form.setFieldsValue(newRecords);
      settypeFilter(newRecords.order_type);
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setOrderDatils("");
    // setIsRejectModalVisible(false);
    // form.resetFields();
    // setEditOrders("");
    // setViewOrders("");
    // setQuantityError("");
    // setcreateOrders("");
    // setReservedQuantityError("");
    // setReservedQuantity("");
  };

  return (
    <div>
      <Typography.Title level={4} style={{ textAlign: "center" }}>
        Dashboard
      </Typography.Title>
      <Space direction="horizontal" style={{ marginBottom: "10px" }}>
        <Card
          style={{
            backgroundColor: "rgba(255, 165, 0, 0.1)",
          }}
        >
          <ShoppingCartOutlined style={{ color: "orange", fontSize: 24 }} />
          &nbsp; ORDERS : {orders?.toLocaleString()}
        </Card>
        <Card
          style={{
            backgroundColor: "rgba(0, 0, 255, 0.1)",
          }}
        >
          <ShoppingOutlined style={{ color: "blue", fontSize: 24 }} />
          &nbsp; INVENTORY : {inventory}
        </Card>
        <Card
          style={{
            backgroundColor: "rgba(128, 0, 128, 0.1",
          }}
        >
          <UserOutlined style={{ color: "purple", fontSize: 24 }} />
          &nbsp; CUSTOMERS : {customers?.toLocaleString()}
        </Card>
        <Card
          style={{
            backgroundColor: "rgba(175, 253, 111, 0.26)",
          }}
        >
          <SendOutlined style={{ color: "green", fontSize: 24 }} />
          &nbsp; DISPATCH : {dispatch?.toLocaleString()}
        </Card>
        <Card
          style={{
            backgroundColor: "rgba(255, 0, 0, 0.1)",
          }}
        >
          <BellOutlined style={{ color: "red", fontSize: 24 }} />
          &nbsp; ALERT : {alert?.toLocaleString()}
        </Card>
      </Space>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{ maxHeight: "320px", overflowY: "auto", flexBasis: "80%" }}
        >
          <Typography.Text
            style={{
              paddingBottom: "10px",
              display: "block",
              textAlign: "center",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 2,
            }}
          >
            <b>Recent Orders</b>
          </Typography.Text>

          <table
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f0f0f0",
                  position: "sticky",
                  top: 31,
                  zIndex: 1,
                }}
              >
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                  Serial No
                </th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                  Order ID
                </th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                  Customer
                </th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {dataSource?.map((order, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {index + 1}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    <a
                      // href={`/Order/Order/${order.order_number}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleOrderModalPopup(order.order_number)}
                    >
                      {order?.order_number}
                    </a>
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {order?.customer_name}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    <Tag color={getTagColor(order?.order_status)}>
                      {order?.order_status}
                    </Tag>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div
          style={{
            position: "relative",
            maxHeight: "320px",
            overflowY: "auto",
            flexBasis: "70%",
            marginRight: "10px",
            textAlign: "start",
            marginLeft: "10px",
          }}
        >
          <Typography.Text
            style={{
              paddingBottom: "10px",
              display: "block",
              textAlign: "center",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 2,
            }}
          >
            <b>Notifications</b>
          </Typography.Text>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {notifications?.map((notification, index) => (
              <li key={index} style={{ marginBottom: "5px" }}>
                <Tag color={getTagListColor(notification.order_status)}>
                  {notification.notification}
                </Tag>
                <Tooltip title="Clear Notification">
                  <Button
                    icon={<CloseCircleOutlined style={{ color: "red" }} />}
                    onClick={() => handleClearNotification(notification.id)}
                  ></Button>
                  {/* <CloseCircleOutlined   /> */}
                </Tooltip>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Modal
        title={`View Order - ${orderDetails}`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        loading={loading}
        // help={reservedQuantityError}
        maskClosable={false}
        centered
      >
        <OrderForm
          formatDate={formatDate}
          form={form}
          viewOrders={viewOrders}
          TextArea={TextArea}
          loggedinuser={loggedinuser}
          JumboOptions={JumboOptions}
          customerOptions={customerOptions}
          LocationOptions={LocationOptions}
          LabelOptions={LabelOptions}
          MaterialOptions={MaterialOptions}
          selectedCoreId={selectedCoreId}
          currentUrl={currentUrl}
          typeFilter={typeFilter}
          ribbonOptions={ribbonOptions}
        />
      </Modal>
    </div>
  );
};

export default Dashboard;
